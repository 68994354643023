import { cloneElement, forwardRef, useCallback, useState } from "react";
import { useField } from "remix-validated-form";

import { EyeIcon } from "~/components/svg/icons";
import { cn } from "~/utils/classnames";

import "./Input.css";
import type { InputProps } from "./Input.types";

/**
 * Composant d'entrée de texte pour les formulaires.
 */

export const Input = forwardRef<HTMLInputElement | HTMLTextAreaElement, InputProps>(
  function ForwardedRefInput(
    {
      id,
      name,
      label,
      type = "text",
      isTextArea = false,
      errorMessage,
      infoMessage,
      IconComponent,
      onIconClick,
      iconClassName,
      absoluteComponent,
      className,
      ...props
    },
    ref
  ) {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    let Icon;
    if (type === "password") {
      Icon = cloneElement(<EyeIcon />, {
        className: cn("Input-icon svgIcon", iconClassName),
      });
    }
    if (IconComponent) {
      Icon = cloneElement(IconComponent, {
        className: cn("Input-icon svgIcon", iconClassName),
      });
    }

    const handleIconClick = useCallback(
      (e: any) => {
        e.preventDefault();
        if (type === "password") {
          return setIsPasswordVisible((prev) => !prev);
        }
        if (onIconClick) {
          return onIconClick(e);
        }
      },
      [type, onIconClick]
    );

    const customType = isPasswordVisible ? "text" : type;

    if (ref) {
      props.ref = ref;
    }

    return (
      <div className={cn("Input", className)}>
        {label ? (
          <label htmlFor={id} className="Input-label">
            {label}
          </label>
        ) : null}
        <div className="Input-container">
          {isTextArea ? (
            <textarea
              className="Input-field Input-field--textarea h-full leading-normal"
              name={name}
              id={id}
              aria-describedby={`${name}-info ${name}-error`}
              {...props}
            />
          ) : (
            <input
              type={customType}
              className="Input-field h-full"
              name={name}
              id={id}
              aria-describedby={`${name}-info ${name}-error`}
              {...props}
            />
          )}

          {Icon ? (
            <button
              type="button"
              className="Input-button"
              onClick={handleIconClick}
              aria-label={isPasswordVisible ? "Cacher le mot de passe" : "Afficher le mot de passe"}
            >
              {Icon}
            </button>
          ) : null}
          {absoluteComponent ? absoluteComponent : null}
        </div>
        {infoMessage ? (
          <span className="Input-infoMessage" id={`${name}-info`}>
            {infoMessage}
          </span>
        ) : null}
        {errorMessage ? (
          <span className="Input-errorMessage" id={`${name}-error`} role="alert">
            {errorMessage}
          </span>
        ) : null}
      </div>
    );
  }
);

export const ValidatedFormInput = forwardRef<HTMLInputElement, InputProps>(
  function ValidatedForwardedRefInput(props: InputProps, ref) {
    const { error, getInputProps, validate } = useField(props.name);

    return (
      <Input
        onBlur={validate}
        {...getInputProps()}
        {...props}
        ref={ref}
        errorMessage={error || ""}
      />
    );
  }
);
